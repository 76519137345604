<template>
    <div class="Transportations" :style="bgimgUrl">
        <div class="Transportations_contan">
            <div class="header">
                <div class="title">
                    <h3>
                        {{$t('TransportationAccommodation.Title')}}
                    </h3>
                </div>
            </div>
            <div class="main_warp">
                <div class="left">
                    <ul class="Vehicle">
                        <li v-for="(item,index) in toodolist" :key="item.id"
                            :class="{show:index ==select }"
                            @mouseenter="selectVehicle(index)"
                            @click="selectVehicle(index)"
                        >
                            <p>{{item.list}}</p>
                        </li>
                    </ul>
                </div>
                <div class="right">
                    <div class="Bus" :style="{
                        backgroundImage:`url('${pickerMain[6]}')`,
                        ...Bus,
                    }">
                        <!--                        <div class="consulmap">-->
                        <!--                            {{$t('TransportationAccommodation.ConsultMap')}}-->
                        <!--                        </div>-->
                        <div class="details">
                            <div>
                                <h3>{{detailsModelList[select].title}}</h3>
                            </div>
                            <p class="address">
                                <span v-for="span in detailsModelList[select].addressList" v-html="span"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            const detailModelList = [
                {
                    title: this.$t('TransportationAccommodation.Details'),
                    addressList: [
                        this.$t('TransportationAccommodation.Address0'),
                        this.$t('TransportationAccommodation.Address1'),
                    ],
                }, {
                    title: this.$t('TransportationDetailList.Bus.Title'),
                    addressList: this.$t('TransportationDetailList.Bus.Details').split('\n'),
                }, {
                    title: this.$t('TransportationDetailList.Air.Title'),
                    addressList: this.$t('TransportationDetailList.Air.Details').split('\n'),
                }, {
                    title: this.$t('TransportationDetailList.Taxi.Title'),
                    addressList: this.$t('TransportationDetailList.Taxi.Details').split('\n'),
                }, {
                    title: this.$t('TransportationDetailList.Metro.Title'),
                    addressList: this.$t('TransportationDetailList.Metro.Details').split('\n'),
                }, {
                    title: this.$t('TransportationDetailList.Hume.Title'),
                    addressList: this.$t('TransportationDetailList.Hume.Details').split('\n'),
                },
            ];
            return {
                detailsModelList: detailModelList,
                select: 0,
                bgimgUrl: {
                    backgroundImage: "url(/images/lodin.png)",
                    backgroundRepeat: "no-repeat",
                },
                Bus: {
                    // backgroundImage: "url(/images/DF8B7248.jpg)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: 'center',
                    backgroundSize: '100% 100%',
                },
                city: {
                    backgroundImage: "url(/images/Exhibitorsservice/bg_juxing@2x.png)",
                    backgroundRepeat: "no-repeat",
                },
                toodolist: [
                    {list: this.$t('TransportationDetailList.Left.0'), id: 0},
                    {list: this.$t('TransportationDetailList.Left.1'), id: 1},
                    {list: this.$t('TransportationDetailList.Left.2'), id: 2},
                    {list: this.$t('TransportationDetailList.Left.3'), id: 3},
                    {list: this.$t('TransportationDetailList.Left.4'), id: 4},
                    {list: this.$t('TransportationDetailList.Left.5'), id: 5}
                ],
            }
        },
        methods: {
            selectVehicle(index) {
                this.select = index
            }
        }
    }
</script>

<style lang="scss" scoped>
    .Transportations {
        width: 100%;
        background: rgba(246, 246, 246, 1);
        background-position-y: 533px;
        padding-bottom: 90px;

        > > > a {
            color: #337ab7;
        }

        .Transportations_contan {
            margin: auto;
            @include design-width-setting;

            .header {
                display: flex;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;

                .title {
                    @include titles();

                    line-height: 32px;
                    margin-bottom: px2rem(42);
                    padding-bottom: 3px;
                    padding-top: 100px;

                    h3 {
                        font-size: 26px;
                        font-weight: 600;
                        color: #333333;

                    }
                }

                .tool {
                    height: 32px;
                    display: flex;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    margin-left: 162px;

                    .toollist {
                        line-height: 32px;

                        li {
                            font-weight: 600;
                            color: rgba(51, 51, 51, 1);
                            float: left;

                            margin-right: 62px;

                            &.action_buttom {
                                border-bottom: 3px solid rgba(250, 210, 20, 1);
                            }
                        }
                    }
                }
            }

            .main_warp {
                border: px2rem(20) solid #ffffff;
                display: flex;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;

                .left {
                    background: #ffffff;
                    width: 25%;

                    .Vehicle {
                        width: 100%;

                        li {
                            width: 100%;
                            cursor: pointer;
                            line-height: 30px;
                            font-size: 14px;
                            padding: px2rem(38) 0;
                            border-bottom: 1px dashed #CCCCCC;
                            background: transparent;
                            transition: .2s linear;

                            p {
                                margin: auto;
                                display: block;
                                text-align: center;
                                font-weight: 400;
                                color: rgba(51, 51, 51, 1);
                            }

                            &.show {
                                background: #FAD214;

                                p {
                                    color: #333333;
                                }
                            }
                        }

                        @include sm {

                        }
                    }

                    .hidden {
                        width: 334px;
                        height: 2px;
                        background: #ffffff;
                    }
                }

                .right {
                    width: 80%;
                    margin-left: -1px;
                    margin-top: -1px;

                    .Bus {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        background-size: cover;

                        .consulmap {
                            float: right;
                            width: 120px;
                            height: 42px;
                            background: #FAD214;
                            border-radius: 20px 0 0 20px;
                            line-height: 42px;
                            text-align: center;
                            margin-top: 40px;
                        }

                        .details {
                            position: absolute;
                            bottom: 24px;
                            left: 35px;
                            box-sizing: border-box;
                            right: 35px;
                            overflow: hidden;

                            .details-svg {
                                width: 100%;
                                height: 60px;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }

                            h3 {
                                padding: 14px 20px 0 30px;
                                font-weight: 600;
                                color: rgba(51, 51, 51, 1);
                                line-height: 40px;
                                position: relative;
                                z-index: 1;
                                display: inline-block;
                                background: rgba(#fff, .85);

                                &:after {
                                    content: '';
                                    position: absolute;
                                    left: 100%;
                                    width: 0;
                                    border: 27px solid transparent;
                                    top: 0;
                                    border-color: transparent transparent rgba(#fff, .85) rgba(#fff, .85);
                                }
                            }

                            .address {
                                padding: 12px 20px 12px 30px;
                                position: relative;
                                z-index: 1;
                                /*font-weight: 400;*/
                                color: rgba(51, 51, 51, 1);
                                line-height: 1.5;
                                background: rgba(#fff, .95);
                                max-height: 250px;
                                overflow: auto;

                                span {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                @include sm {
                    flex-direction: column;
                    border: none;

                    .left {
                        width: 100%;
                        flex-basis: 100%;
                        border-bottom: 1px dashed #dddddd;

                        .Vehicle {
                            overflow: hidden;

                            li {
                                font-size: 12px;
                                border-bottom: none;
                                float: left;
                                width: (100% / 6);
                            }
                        }
                    }

                    .right {
                        width: 100%;

                        .Bus {
                            .details {
                                position: relative;
                                margin: 0;
                                padding: 0;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background: rgba(#fff, .7);

                                h3 {
                                    background: transparent;
                                }

                                h3:after {
                                    display: none;
                                }

                                .address {
                                    background: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>