import axios from "@/utils/axios"


//合作展商

export function registerCompany({ lang }) {
    return axios({
        url: 'api/public/expo/company',
        method: 'get',
        params: { lang }
    })
}